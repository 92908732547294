a.active {
    color: #219221;
  }
  
  .btn-game-type{
    user-select: none;
    background: #FF8503 none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 0;
    padding: 18px 33px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    /* clip-path: polygon(90% 0, 100% 34%, 100% 100%, 10% 100%, 0 66%, 0 0); */
    border-radius: 0;
    position: relative;
    font-family: "Oswald", sans-serif;
  }

  .nav-logo{
    width: 143px;
  }
  
  @media only screen and (max-width: 768px) {
    .nav-logo{
      width: 100px;
    }         
  }
  
  
  @media only screen and (max-width: 540px) {
    .nav-logo{
      width: 86px;
    }         
  }
  
  @media only screen and (max-width: 430px) {
    .nav-logo{
      width: 76px;
    }         
  }
  
  