.redirect-container{
    margin-top:8%;
}

.main-title{
    text-align: center;
    margin-top: 16px;
}

.order-receipt-label{
    text-align: right;
    font-size: 18px;
    font-weight: 500;
    
}

.order-receipt-value{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color:antiquewhite;

}



  .payment-redirect-logo{
    width: 256px;
  }
  
  @media only screen and (max-width: 768px) {
    .payment-redirect-logo{
      width: 200px;
    }         
  }
  
  
  @media only screen and (max-width: 540px) {
    .payment-redirect-logo{
      width: 165px;
    }         
  }
  
  @media only screen and (max-width: 430px) {
    .payment-redirect-logo{
      width: 145px;
    }    
    
    .main-title h3{
        font-size: 23px;
    }
    .order-receipt-label{
      font-size: 14px;
    }
    .order-receipt-value{
      font-size:14px;
    }
  }