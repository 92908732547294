body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.active {
    color: #219221;
  }
  
  .btn-game-type{
    -webkit-user-select: none;
            user-select: none;
    background: #FF8503 none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 0;
    padding: 18px 33px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    /* clip-path: polygon(90% 0, 100% 34%, 100% 100%, 10% 100%, 0 66%, 0 0); */
    border-radius: 0;
    position: relative;
    font-family: "Oswald", sans-serif;
  }

  .nav-logo{
    width: 143px;
  }
  
  @media only screen and (max-width: 768px) {
    .nav-logo{
      width: 100px;
    }         
  }
  
  
  @media only screen and (max-width: 540px) {
    .nav-logo{
      width: 86px;
    }         
  }
  
  @media only screen and (max-width: 430px) {
    .nav-logo{
      width: 76px;
    }         
  }
  
  
.shop-content-top .shop-title .title {
   
    margin-bottom: 5px;
    color: #D3710B;;
}
.shop-content-top .shop-title .title a:hover {
    color: #FF8503;;
}
.shop-content-top .shop-title p {
    font-size: 15px;
    text-transform: uppercase;
    color: #bababa;
    font-weight: 400;
    margin-bottom: 0;
}

@media only screen and (min-width: 1400px) {
    .shop-content-top .shop-title .title {
        font-size: 1vw;
    }
}

@media only screen and (min-width: 1200px) {
    .shop-content-top .shop-title .title {
        font-size: 1vw;
    }
}

@media only screen and (min-width: 992px) {
    .shop-content-top .shop-title .title {
        font-size: 1.15vw;
    }
}

@media only screen and (min-width: 768px) and (max-width : 991px){
    .shop-content-top .shop-title .title {
        font-size: 1.8vw;
    }
}

@media only screen and (min-width: 576px)  and (max-width : 767px) {
    .shop-content-top .shop-title .title {
        font-size: 2.4vw;
    }
}

@media only screen and (max-device-width: 575px) and (min-width: 240px) {
    .shop-content-top .shop-title .title {
        font-size: 3vw;
    }
}
.app-features-block{
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto auto auto;
}

@media screen and (max-width : 991px) {
    .app-features-block{
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width : 768px) {
    .app-features-block{
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width : 576px) {
    .app-features-block{
        grid-template-columns: auto auto;
    }
}


@media screen and (max-width : 460px) {
    .app-features-block{
        justify-content: center;
        grid-template-columns: auto;
    }
}
.topupTypeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topUpType{
    margin-top: 12px;
    padding: 8px;
    background-color:#E88014;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 32%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
            clip-path: polygon(0 0, 96% 0, 100% 32%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
    color:#000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    width: 90%; 
    cursor: pointer;

}

.topUpType:hover{
  background-color:#e89b4a ;
  transition: all 0.25s ease 0.1s;
}

.gameItemList{
  display:grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
  gap: 16px;
}


form.search input[type=text] {
    padding: 11px 32px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background:#E88015;
    font-size:16px;
    font-weight:700;
    color: white;
    /* padding:16px 32px; */
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 32%, 100% 80%, 100% 100%, 2% 100%, 0 71%, 0% 20%);
            clip-path: polygon(0 0, 100% 0, 100% 32%, 100% 80%, 100% 100%, 2% 100%, 0 71%, 0% 20%);
  }

  form.search .clearBtn{
   position: absolute;
   margin-top: 12px;
   margin-left: 8px;
   z-index: 5;
    
  }

  form.search input[type=text]::selection {
    background-color:rgb(32, 108, 208);
  }

  input:-internal-autofill-selected{
    background-color: #FFE4C4;
  } 
  form.search .clearBtn i{
    color: "#f1f1f1";
    font-size: 16px;
  }
  input:-internal-autofill-selected form.search .clearBtn i{
    color: #E88015;
  }
  
  form.search button {
    float: left;    
    width: 20%;
    padding: 10px;
    background:#B16011;
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none;
    cursor: pointer;
    margin-left: -60px;
    -webkit-clip-path: polygon(0 0, 94% 0, 100% 33%, 100% 80%, 100% 100%, 6% 100%, 0 71%, 0% 20%);
            clip-path: polygon(0 0, 94% 0, 100% 33%, 100% 80%, 100% 100%, 6% 100%, 0 71%, 0% 20%);
  }
  
  form.search button:hover {
    background: #e67708;
  }
  
  form.search::after {
    content: "";
    clear: both;
    display: table;
  }
.gameDetailFormlabel {
  font-size: 1.2vw;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.gameImageBlk {
  display: flex;
  justify-content: center;
}

.gameDetailFormInput {
  color: #1e1e1e;
  background-color: bisque;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 0px;
  -webkit-clip-path: polygon(
    0 0,
    93% 0,
    100% 34%,
    100% 100%,
    80% 100%,
    0 100%,
    0% 80%,
    0 20%
  );
          clip-path: polygon(
    0 0,
    93% 0,
    100% 34%,
    100% 100%,
    80% 100%,
    0 100%,
    0% 80%,
    0 20%
  );
}

.gameDetailFormInput:focus {
  background-color: antiquewhite;
  color: #fff;
  color: #1d1d1d;
}

.gameDetailParagraph {
  line-height: 22px;
}

.gameItemContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
  gap: 16px;
}

.gameItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px 8px 8px 8px;
  width: 70%;
  min-height: 120px;
  /* border: 1px solid blue; */
  background-color: #333333;
  -webkit-clip-path: polygon(
    0 0,
    80% 0%,
    100% 20%,
    100% 80%,
    100% 100%,
    0 100%,
    0% 80%,
    0% 20%
  );
          clip-path: polygon(
    0 0,
    80% 0%,
    100% 20%,
    100% 80%,
    100% 100%,
    0 100%,
    0% 80%,
    0% 20%
  );
  font-size: 0.8vw;
}
.gameItem img {
  margin-top: 0px;
  width: 40%;
  object-fit: cover;
}

.gameItem:hover {
  background-color: #ff8503;
}

.gameItem p {
  margin-top: 8px;
  line-height: 20px;
}

.gameItemActive {
  background-color: #ff8503;
}

.gameTypeContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
  gap: 24px;
}

.gameType {
  justify-content: center;
  padding: 16px 24px;
  background-color: #ff8503;
  width: 65%;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  font-size: 0.9vm;
}

.accordion-button {
  background-color: #ff8503;
  border: 1px solid #ff8503;
}

.accordion-body {
  background-color: #1f2229;
}

.accordion-button:not(.collapsed) {
  background-color: #ff8503;
  border: 1px solid #ff8503;
  color: white;
}

.btn-order-now {
  width: 100%;
  text-align: center;
  color: white;  
  font-size: 1vw;
  background: #ff8503 none repeat scroll 0 0;
  display: inline-flex;
  text-transform: uppercase;
  -webkit-clip-path: polygon(90% 0, 100% 34%, 100% 100%, 10% 100%, 0 66%, 0 0);
          clip-path: polygon(90% 0, 100% 34%, 100% 100%, 10% 100%, 0 66%, 0 0);
  justify-content: center;  
  padding:16px 24px;
  
}

.btn-order-now:hover {
  text-align: center;
  color: black;
  background-color: white;
}

.wallets-show {
  width: 100%;
  /* border: 1px solid blue; */
  display: grid;
}

.paymentMethod-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
}

.packageAndPrice {
  font-size: 1vw;
  font-weight: bold;
}

.paymentMethodBlk {
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4px;
  max-width: 96px;
}

.paymentActive {
  background-color: #fdc68d;
  color: #1e1e1e;

  border-radius: 10px;
}

.paymentMethodBlk img {
  width: 50%;
}

.paymentMethodBlk .paymentTitle {
  margin-top: 4px;
  font-size: 1.09vw;
}

.btnCheckUserInfo {
  color: white;
  max-width: 122px;
  background-color: #ff8503;
  padding: 4px;
  margin: 16px 0px;
}

.userValidResult {
  margin-top: 20px;
  margin-left: 16px;
  /* border:1px solid red; */
  padding: 0px;
  height: 27px;
}

@media screen and (max-width: 1080px) {
  .gameDetailFormlabel {
    font-size: 1.8vw;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 1.25vw;
  }
  .packageAndPrice {
    font-size: 1.25vw;
  }
  .gameType{
    font-size: 1.35vw;
  }
}

@media screen and (max-width: 765x) {
  .gameDetailFormlabel {
    font-size: 2.5vw;
  }
  .btn-order-now {
    text-align: center;
    font-size: 2.5vw;
  }
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
  .packageAndPrice {
    font-size: 2.5vw;
  }
  .gameType{
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 326x) {
  .gameDetailFormlabel {
    font-size: 4vw;
    display: none;
  }
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .gameDetailFormlabel {
    font-size: 1.8vw;
  }

  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }

  .gameItem {
    width: 60%;
  }
}

@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .gameDetailFormlabel {
    font-size: 1.2vw;
  }

  .paymentMethodBlk .paymentTitle {
    margin-top: 4px;
    font-size: 0.8vw;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .gameDetailFormlabel {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 1520px) {
  /* styles for mobile browsers smaller than 1520px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: 1280px) {
  /* styles for mobile browsers smaller than 1280px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: 1111px) {
  /* styles for mobile browsers smaller than 1080px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto;
  }
  .gameItemContainer {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 992px) {
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
  .gameItem {
    width: 65%;
  }
  
}

@media only screen and (max-width: 960px) {
  /* styles for mobile browsers smaller than 1080px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 1.5vw;
  }

  .packageAndPrice {
    font-size: 1.5vw;
  }

  .btn-order-now {
    text-align: center;
    font-size: 2vw;
  }
  .gameType{
    font-size: 2vw;
  }
}

@media only screen and (max-width: 768px) {
  /* styles for mobile browsers smaller than 768px; (iPhone) */
  .gameDetailFormlabel {
    font-size: 3vw;
  }

  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }

  .packageAndPrice {
    font-size: 2.5vw;
  }

  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }

  .paymentMethodBlk .paymentTitle {
    font-size: 2vw;
  }

  .btn-order-now {
    text-align: center;
    font-size: 2.3vw;
  }

  .gameItem {
    width: 77%;
  }
  .gameType{
    font-size: 2.8vw;
  }
}

@media only screen and (max-width: 520px) {
  /* styles for mobile browsers smaller than 520px;  */
  .gameDetailFormlabel {
    font-size: 4vw;
    /* display: none; */
  }

  .gameItemContainer {
    grid-template-columns: auto auto;
  }

  .btn-order-now {
    text-align: center;
    font-size: 2.7vw;
  }

  .paymentMethod-grid {
    grid-template-columns: auto auto;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 2.5vw;
  }

  .packageAndPrice {
    font-size: 3.5vw;
  }
  .gameItem {
    width: 63%;
  }
}

@media only screen and (max-width: 410px) {
  .gameItemContainer {
    grid-template-columns: auto auto;
  }

  .paymentMethod-grid {
    grid-template-columns: auto auto;
  }

  .gameItem {
    padding: 24px 8px 8px 8px;
    width: 100%;
  }
  .gameItem p {
    font-size: 3vw;
  }
  .btn-order-now {
    text-align: center;
    font-size: 2.2vw;
  }
  .paymentMethodBlk {
    width: 70%;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 3vw;
  }

  .packageAndPrice {
    font-size: 4vw;
  }
}

.redirect-container{
    margin-top:8%;
}

.main-title{
    text-align: center;
    margin-top: 16px;
}

.order-receipt-label{
    text-align: right;
    font-size: 18px;
    font-weight: 500;
    
}

.order-receipt-value{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color:antiquewhite;

}



  .payment-redirect-logo{
    width: 256px;
  }
  
  @media only screen and (max-width: 768px) {
    .payment-redirect-logo{
      width: 200px;
    }         
  }
  
  
  @media only screen and (max-width: 540px) {
    .payment-redirect-logo{
      width: 165px;
    }         
  }
  
  @media only screen and (max-width: 430px) {
    .payment-redirect-logo{
      width: 145px;
    }    
    
    .main-title h3{
        font-size: 23px;
    }
    .order-receipt-label{
      font-size: 14px;
    }
    .order-receipt-value{
      font-size:14px;
    }
  }
.not-found-container{
    /* border: 1px solid red; */
    margin:auto;
    margin-top:8%;
}

.main-title{
    text-align: center;
    margin-top: 16px;
    color:#E88014;
    font-size: 4vw;
}

.not-found-img{
    width: 35%;
}

.not-found-text{
    font-size: 16px;
}

@media screen and (max-width : 992px) {
    .not-found-container{
        margin-top : 20%;
    }
    .not-found-text{
        font-size: 14px;
    }

    .back-to-home{
        font-size: 2vw;
    }
}

@media screen and (max-width : 760px) {
    .not-found-container{
        margin-top : 30%;
    }

    .not-found-text{
        font-size: 12px;
    }

    .back-to-home{
        font-size: 2.5vw;
    }
}

@media screen and (max-width : 510px) {
    .not-found-container{
        margin-top : 50%;
    }

    .not-found-text{
        font-size: 10px;
    }

    .back-to-home{
        font-size: 3vw;
    }
}
