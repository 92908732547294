.app-features-block{
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto auto auto;
}

@media screen and (max-width : 991px) {
    .app-features-block{
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width : 768px) {
    .app-features-block{
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width : 576px) {
    .app-features-block{
        grid-template-columns: auto auto;
    }
}


@media screen and (max-width : 460px) {
    .app-features-block{
        justify-content: center;
        grid-template-columns: auto;
    }
}