.not-found-container{
    /* border: 1px solid red; */
    margin:auto;
    margin-top:8%;
}

.main-title{
    text-align: center;
    margin-top: 16px;
    color:#E88014;
    font-size: 4vw;
}

.not-found-img{
    width: 35%;
}

.not-found-text{
    font-size: 16px;
}

@media screen and (max-width : 992px) {
    .not-found-container{
        margin-top : 20%;
    }
    .not-found-text{
        font-size: 14px;
    }

    .back-to-home{
        font-size: 2vw;
    }
}

@media screen and (max-width : 760px) {
    .not-found-container{
        margin-top : 30%;
    }

    .not-found-text{
        font-size: 12px;
    }

    .back-to-home{
        font-size: 2.5vw;
    }
}

@media screen and (max-width : 510px) {
    .not-found-container{
        margin-top : 50%;
    }

    .not-found-text{
        font-size: 10px;
    }

    .back-to-home{
        font-size: 3vw;
    }
}