.shop-content-top .shop-title .title {
   
    margin-bottom: 5px;
    color: #D3710B;;
}
.shop-content-top .shop-title .title a:hover {
    color: #FF8503;;
}
.shop-content-top .shop-title p {
    font-size: 15px;
    text-transform: uppercase;
    color: #bababa;
    font-weight: 400;
    margin-bottom: 0;
}

@media only screen and (min-width: 1400px) {
    .shop-content-top .shop-title .title {
        font-size: 1vw;
    }
}

@media only screen and (min-width: 1200px) {
    .shop-content-top .shop-title .title {
        font-size: 1vw;
    }
}

@media only screen and (min-width: 992px) {
    .shop-content-top .shop-title .title {
        font-size: 1.15vw;
    }
}

@media only screen and (min-width: 768px) and (max-width : 991px){
    .shop-content-top .shop-title .title {
        font-size: 1.8vw;
    }
}

@media only screen and (min-width: 576px)  and (max-width : 767px) {
    .shop-content-top .shop-title .title {
        font-size: 2.4vw;
    }
}

@media only screen and (max-device-width: 575px) and (min-width: 240px) {
    .shop-content-top .shop-title .title {
        font-size: 3vw;
    }
}