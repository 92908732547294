.topupTypeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topUpType{
    margin-top: 12px;
    padding: 8px;
    background-color:#E88014;
    clip-path: polygon(0 0, 96% 0, 100% 32%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
    color:#000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    width: 90%; 
    cursor: pointer;

}

.topUpType:hover{
  background-color:#e89b4a ;
  transition: all 0.25s ease 0.1s;
}

.gameItemList{
  display:grid;
  grid-template-columns: auto auto auto auto;
  gap: 16px;
}


form.search input[type=text] {
    padding: 11px 32px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background:#E88015;
    font-size:16px;
    font-weight:700;
    color: white;
    /* padding:16px 32px; */
    clip-path: polygon(0 0, 100% 0, 100% 32%, 100% 80%, 100% 100%, 2% 100%, 0 71%, 0% 20%);
  }

  form.search .clearBtn{
   position: absolute;
   margin-top: 12px;
   margin-left: 8px;
   z-index: 5;
    
  }

  form.search input[type=text]::selection {
    background-color:rgb(32, 108, 208);
  }

  input:-internal-autofill-selected{
    background-color: #FFE4C4;
  } 
  form.search .clearBtn i{
    color: "#f1f1f1";
    font-size: 16px;
  }
  input:-internal-autofill-selected form.search .clearBtn i{
    color: #E88015;
  }
  
  form.search button {
    float: left;    
    width: 20%;
    padding: 10px;
    background:#B16011;
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none;
    cursor: pointer;
    margin-left: -60px;
    clip-path: polygon(0 0, 94% 0, 100% 33%, 100% 80%, 100% 100%, 6% 100%, 0 71%, 0% 20%);
  }
  
  form.search button:hover {
    background: #e67708;
  }
  
  form.search::after {
    content: "";
    clear: both;
    display: table;
  }