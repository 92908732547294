.gameDetailFormlabel {
  font-size: 1.2vw;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.gameImageBlk {
  display: flex;
  justify-content: center;
}

.gameDetailFormInput {
  color: #1e1e1e;
  background-color: bisque;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 0px;
  clip-path: polygon(
    0 0,
    93% 0,
    100% 34%,
    100% 100%,
    80% 100%,
    0 100%,
    0% 80%,
    0 20%
  );
}

.gameDetailFormInput:focus {
  background-color: antiquewhite;
  color: #fff;
  color: #1d1d1d;
}

.gameDetailParagraph {
  line-height: 22px;
}

.gameItemContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 16px;
}

.gameItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px 8px 8px 8px;
  width: 70%;
  min-height: 120px;
  /* border: 1px solid blue; */
  background-color: #333333;
  clip-path: polygon(
    0 0,
    80% 0%,
    100% 20%,
    100% 80%,
    100% 100%,
    0 100%,
    0% 80%,
    0% 20%
  );
  font-size: 0.8vw;
}
.gameItem img {
  margin-top: 0px;
  width: 40%;
  object-fit: cover;
}

.gameItem:hover {
  background-color: #ff8503;
}

.gameItem p {
  margin-top: 8px;
  line-height: 20px;
}

.gameItemActive {
  background-color: #ff8503;
}

.gameTypeContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}

.gameType {
  justify-content: center;
  padding: 16px 24px;
  background-color: #ff8503;
  width: 65%;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  font-size: 0.9vm;
}

.accordion-button {
  background-color: #ff8503;
  border: 1px solid #ff8503;
}

.accordion-body {
  background-color: #1f2229;
}

.accordion-button:not(.collapsed) {
  background-color: #ff8503;
  border: 1px solid #ff8503;
  color: white;
}

.btn-order-now {
  width: 100%;
  text-align: center;
  color: white;  
  font-size: 1vw;
  background: #ff8503 none repeat scroll 0 0;
  display: inline-flex;
  text-transform: uppercase;
  clip-path: polygon(90% 0, 100% 34%, 100% 100%, 10% 100%, 0 66%, 0 0);
  justify-content: center;  
  padding:16px 24px;
  
}

.btn-order-now:hover {
  text-align: center;
  color: black;
  background-color: white;
}

.wallets-show {
  width: 100%;
  /* border: 1px solid blue; */
  display: grid;
}

.paymentMethod-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
}

.packageAndPrice {
  font-size: 1vw;
  font-weight: bold;
}

.paymentMethodBlk {
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4px;
  max-width: 96px;
}

.paymentActive {
  background-color: #fdc68d;
  color: #1e1e1e;

  border-radius: 10px;
}

.paymentMethodBlk img {
  width: 50%;
}

.paymentMethodBlk .paymentTitle {
  margin-top: 4px;
  font-size: 1.09vw;
}

.btnCheckUserInfo {
  color: white;
  max-width: 122px;
  background-color: #ff8503;
  padding: 4px;
  margin: 16px 0px;
}

.userValidResult {
  margin-top: 20px;
  margin-left: 16px;
  /* border:1px solid red; */
  padding: 0px;
  height: 27px;
}

@media screen and (max-width: 1080px) {
  .gameDetailFormlabel {
    font-size: 1.8vw;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 1.25vw;
  }
  .packageAndPrice {
    font-size: 1.25vw;
  }
  .gameType{
    font-size: 1.35vw;
  }
}

@media screen and (max-width: 765x) {
  .gameDetailFormlabel {
    font-size: 2.5vw;
  }
  .btn-order-now {
    text-align: center;
    font-size: 2.5vw;
  }
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
  .packageAndPrice {
    font-size: 2.5vw;
  }
  .gameType{
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 326x) {
  .gameDetailFormlabel {
    font-size: 4vw;
    display: none;
  }
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .gameDetailFormlabel {
    font-size: 1.8vw;
  }

  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }

  .gameItem {
    width: 60%;
  }
}

@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .gameDetailFormlabel {
    font-size: 1.2vw;
  }

  .paymentMethodBlk .paymentTitle {
    margin-top: 4px;
    font-size: 0.8vw;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .gameDetailFormlabel {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 1520px) {
  /* styles for mobile browsers smaller than 1520px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: 1280px) {
  /* styles for mobile browsers smaller than 1280px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: 1111px) {
  /* styles for mobile browsers smaller than 1080px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto;
  }
  .gameItemContainer {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 992px) {
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
  .gameItem {
    width: 65%;
  }
  
}

@media only screen and (max-width: 960px) {
  /* styles for mobile browsers smaller than 1080px; */
  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }
  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 1.5vw;
  }

  .packageAndPrice {
    font-size: 1.5vw;
  }

  .btn-order-now {
    text-align: center;
    font-size: 2vw;
  }
  .gameType{
    font-size: 2vw;
  }
}

@media only screen and (max-width: 768px) {
  /* styles for mobile browsers smaller than 768px; (iPhone) */
  .gameDetailFormlabel {
    font-size: 3vw;
  }

  .paymentMethod-grid {
    grid-template-columns: auto auto auto;
  }

  .packageAndPrice {
    font-size: 2.5vw;
  }

  .gameItemContainer {
    grid-template-columns: auto auto auto;
  }

  .paymentMethodBlk .paymentTitle {
    font-size: 2vw;
  }

  .btn-order-now {
    text-align: center;
    font-size: 2.3vw;
  }

  .gameItem {
    width: 77%;
  }
  .gameType{
    font-size: 2.8vw;
  }
}

@media only screen and (max-width: 520px) {
  /* styles for mobile browsers smaller than 520px;  */
  .gameDetailFormlabel {
    font-size: 4vw;
    /* display: none; */
  }

  .gameItemContainer {
    grid-template-columns: auto auto;
  }

  .btn-order-now {
    text-align: center;
    font-size: 2.7vw;
  }

  .paymentMethod-grid {
    grid-template-columns: auto auto;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 2.5vw;
  }

  .packageAndPrice {
    font-size: 3.5vw;
  }
  .gameItem {
    width: 63%;
  }
}

@media only screen and (max-width: 410px) {
  .gameItemContainer {
    grid-template-columns: auto auto;
  }

  .paymentMethod-grid {
    grid-template-columns: auto auto;
  }

  .gameItem {
    padding: 24px 8px 8px 8px;
    width: 100%;
  }
  .gameItem p {
    font-size: 3vw;
  }
  .btn-order-now {
    text-align: center;
    font-size: 2.2vw;
  }
  .paymentMethodBlk {
    width: 70%;
  }
  .paymentMethodBlk .paymentTitle {
    font-size: 3vw;
  }

  .packageAndPrice {
    font-size: 4vw;
  }
}
